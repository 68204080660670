import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import AllWebinars from '../../components/allWebinars'
import { Button } from '../../components/global'

const W006 = () => (
  <Layout includeNotice={false}>
    <SEO title="Webinar 006: Understanding &amp; Troubleshooting Solenoid Circuits (Part 2 of 2)" />
    <div className="container container-last">
      <h1>
        Webinar 006:
        <br />
        Understanding &amp; Troubleshooting Solenoid Circuits (Part 2 of 2)
      </h1>
      <hr />
      <p>
        Picking up the story of solenoids from Webinar W005 we now discuss on-board computer control
        of solenoid circuits. Find out why two computers are used to control a solenoid circuit.
        Learn about the different types of solid-state circuits used to control solenoids. Learn
        about the one critical voltage drop reading that identifies an on-board computer with a
        defective solenoid driver circuit. See how a PWM (Pulse-Width-Modulated) signal controls a
        solenoid. Learn how to spot a defective PWM control signal. Solenoid circuit voltage
        analysis is explained to learn what good voltage readings occur in a computer-controlled
        solenoid circuit and how circuit voltages change when a problem occurs.
      </p>
      <ul style={{ marginBottom: 0 }}>
        <li>Watch Webinar W006 Session</li>
        <li>
          Purchase a link to download the Webinar W006 recording and watch as often as you like.
          (Download link expires after 7 days)
        </li>
      </ul>
      <Button
        type="secondary"
        text="BUY NOW for $39.00"
        href="http://www.1shoppingcart.com/SecureCart/SecureCart.aspx?mid=2169DBD4-F088-4CA4-A011-56C1593411AC&pid=4fe9b66179514080b14ac7d6d471d742"
      />

      <hr />
      <AllWebinars />
    </div>
  </Layout>
)

export default W006
